import React from 'react';
import Service from './Service';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

function Services() {
    return (
        <div className="py-16">
            <h2 className="text-5xl font-medium text-center mb-3">Servicios</h2>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-center text-red-500 mb-8">
                ¡Reserva desde la web y obtén un 15% de descuento!
            </p>
            <div className="space-y-5">
                <Service
                    title="Lavado a Mano"
                    data={[
                        {
                            title: 'Exterior',
                            prices: [
                                {
                                    category: 'Pequeño',
                                    amounts: {
                                        normal: 9.5,
                                        subscribed: 8,
                                    },
                                },
                                {
                                    category: 'Mediano/Grande',
                                    amounts: {
                                        normal: 10,
                                        subscribed: 9,
                                    },
                                },
                                {
                                    category: 'Comercial',
                                    amounts: {
                                        normal: 12,
                                        subscribed: 11,
                                    },
                                },
                                {
                                    category: 'T.T./Monovolumen corto',
                                    amounts: {
                                        normal: 12,
                                        subscribed: 11,
                                    },
                                },
                                {
                                    category: 'T.T./Monovolumen largo',
                                    amounts: {
                                        normal: 15,
                                        subscribed: 13.5,
                                    },
                                },
                            ],
                        },
                        {
                            title: 'Completo',
                            prices: [
                                {
                                    category: 'Pequeño',
                                    amounts: {
                                        normal: 15,
                                        subscribed: 13.5,
                                    },
                                },
                                {
                                    category: 'Mediano/Grande',
                                    amounts: {
                                        normal: 18,
                                        subscribed: 16,
                                    },
                                },
                                {
                                    category: 'Comercial',
                                    amounts: {
                                        normal: 21,
                                        subscribed: 19,
                                    },
                                },
                                {
                                    category: 'T.T./Monovolumen corto',
                                    amounts: {
                                        normal: 21,
                                        subscribed: 19,
                                    },
                                },
                                {
                                    category: 'T.T./Monovolumen largo',
                                    amounts: {
                                        normal: 24,
                                        subscribed: 21,
                                    },
                                },
                            ],
                        },
                        {
                            title: (
                                <>
                                    Especial
                                    <br />
                                    <span className="text-sm">
                                        (alta suciedad)
                                    </span>
                                </>
                            ),
                            prices: [
                                {
                                    category: 'Pequeño',
                                    amounts: {
                                        normal: 30,
                                        subscribed: 27,
                                    },
                                },
                                {
                                    category: 'Mediano/Grande',
                                    amounts: {
                                        normal: 36,
                                        subscribed: 32,
                                    },
                                },
                                {
                                    category: 'Comercial',
                                    amounts: {
                                        normal: 42,
                                        subscribed: 38,
                                    },
                                },
                                {
                                    category: 'T.T./Monovolumen corto',
                                    amounts: {
                                        normal: 42,
                                        subscribed: 38,
                                    },
                                },
                                {
                                    category: 'T.T./Monovolumen largo',
                                    amounts: {
                                        normal: 48,
                                        subscribed: 42,
                                    },
                                },
                            ],
                        },
                        {
                            title: (
                                <>
                                    Completo
                                    <br />
                                    <span className="text-sm">
                                        (con encerado de carrocería)
                                    </span>
                                </>
                            ),
                            prices: [
                                {
                                    category: 'Pequeño',
                                    amounts: {
                                        normal: 45,
                                        subscribed: 42,
                                    },
                                },
                                {
                                    category: 'Mediano/Grande',
                                    amounts: {
                                        normal: 50,
                                        subscribed: 45,
                                    },
                                },
                                {
                                    category: 'Comercial',
                                    amounts: {
                                        normal: 60,
                                        subscribed: 54,
                                    },
                                },
                                {
                                    category: 'T.T./Monovolumen corto',
                                    amounts: {
                                        normal: 60,
                                        subscribed: 54,
                                    },
                                },
                                {
                                    category: 'T.T./Monovolumen largo',
                                    amounts: {
                                        normal: 75,
                                        subscribed: 69,
                                    },
                                },
                            ],
                        },
                    ]}
                ></Service>
                <Service
                    title="Lavado de Motor"
                    data={[
                        {
                            title: 'Lavado Motor',
                            prices: [
                                {
                                    category: 'Turismos',
                                    amounts: {
                                        normal: 30,
                                        subscribed: 27,
                                    },
                                },

                                {
                                    category: 'T.T./Monovolumen',
                                    amounts: {
                                        normal: 45,
                                        subscribed: 40,
                                    },
                                },
                            ],
                        },
                    ]}
                ></Service>
                <Service
                    title="Tapicería y pulido"
                    data={[
                        {
                            title: (
                                <>
                                    Limpieza en seco de tapicerías
                                    <br />
                                    <span className="text-xs">
                                        Tapicería de piel incremento de 10€ por
                                        plaza
                                    </span>
                                </>
                            ),
                            prices: [
                                {
                                    category: 'Pequeño',
                                    amounts: {
                                        normal: 120,
                                        subscribed: 120,
                                    },
                                },
                                {
                                    category: 'Mediano/Grande',
                                    amounts: {
                                        normal: 150,
                                        subscribed: 150,
                                    },
                                },

                                {
                                    category: 'T.T./Monovolumen Corto',
                                    amounts: {
                                        normal: 180,
                                        subscribed: 180,
                                    },
                                },
                                {
                                    category: 'T.T./Monovolumen Largo',
                                    amounts: {
                                        normal: 210,
                                        subscribed: 210,
                                    },
                                },
                            ],
                        },
                        {
                            title: (
                                <>
                                    Pulido a máquina de carrocería
                                    <br />
                                    <span className="text-xs">
                                        Incluye abrillantado/encerado exterior
                                    </span>
                                </>
                            ),
                            prices: [
                                {
                                    category: 'Pequeño',
                                    amounts: {
                                        normal: 120,
                                        subscribed: 100,
                                    },
                                },
                                {
                                    category: 'Mediano/Grande',
                                    amounts: {
                                        normal: 150,
                                        subscribed: 120,
                                    },
                                },

                                {
                                    category: 'T.T./Monovolumen Corto',
                                    amounts: {
                                        normal: 180,
                                        subscribed: 150,
                                    },
                                },
                                {
                                    category: 'T.T./Monovolumen Largo',
                                    amounts: {
                                        normal: 210,
                                        subscribed: 180,
                                    },
                                },
                            ],
                        },
                    ]}
                ></Service>

                <div className="container mx-auto">
                    <h3 className="text-3xl font-medium mx-auto text-center">
                        Y además...
                    </h3>

                    <p className="text-center max-w-md mb-3 mx-auto">
                        Limpiamos tu tapicería a domicilio. Vamos a por tu
                        coche, aplicamos el tratamiento de tapicería que elijas
                        y te lo devolvemos donde tú nos digas.
                    </p>
                    <AnchorLink
                        to="/#contact"
                        className="flex max-w-xs mx-auto items-center mt-auto text-white bg-primary-600 border-0 py-2 px-4 w-full focus:outline-none hover:bg-primary-500 rounded"
                    >
                        ¡Pregúntanos!
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="w-4 h-4 ml-auto"
                            viewBox="0 0 24 24"
                        >
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                    </AnchorLink>
                </div>
            </div>
        </div>
    );
}

export default Services;
